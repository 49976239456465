import axios from "axios";
import { BACKEND_BASE_URL } from "./baseurl";
import useEncryption from "utils/hooks/useEncryption";
const BACKEND_URL = BACKEND_BASE_URL;

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config) {
    if (config.url !== "doctor/doctor-sign-in") {
      const admin = localStorage.getItem("admin");
      const authData = JSON.parse(admin).auth;
      const token = JSON.parse(authData).session.token;
      config.headers = {
        Authorization: `${token}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data;",
      };
    }

    // Do something before request is sent
    const formData = new FormData();
    if (config.data) {
      if (process.env.REACT_APP_PROJECT_MODE === "development") {
        if (config?.data?.profile) {
          formData.append("avatar", config?.data?.profile);
          delete config?.data?.profile;
          delete config?.data?.avatar;
        } else {
          delete config?.data?.profile;
          delete config?.data?.avatar;
        }
        if (config?.data?.image) {
          formData.append("image", config?.data?.image);
          delete config?.data?.image;
        }
        if (config?.data?.images) {
          config?.data?.images.map((img) => formData.append("images", img));
          delete config?.data?.images;
        }
        if (config?.data?.file) {
          formData.append("file", config?.data?.file);
          delete config?.data?.file;
        }
        if (config?.data?.files) {
          config?.data?.files.map((file) => formData.append("files", file));
          delete config?.data?.files;
        }
        if (config?.data?.service_image) {
          formData.append("image", config?.data?.service_image);
        }
        formData.append("data", JSON.stringify(config.data));
      } else {
        if (config?.data?.profile) {
          formData.append("avatar", config?.data?.profile);
          delete config?.data?.profile;
          delete config?.data?.avatar;
        } else {
          delete config?.data?.profile;
          delete config?.data?.avatar;
        }
        if (config?.data?.image) {
          formData.append("image", config?.data?.image);
          delete config?.data?.image;
        }
        if (config?.data?.images) {
          config?.data?.images.map((img) => formData.append("images", img));
          delete config?.data?.images;
        }
        if (config?.data?.file) {
          formData.append("file", config?.data?.file);
          delete config?.data?.file;
        }
        if (config?.data?.files) {
          formData.append("files", config?.data?.files);
          delete config?.data?.files;
        }
        formData.append("data", await useEncryption.encryptData(config.data));
      }
    }
    config.data = formData;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  async function (response) {
    // Do something with response data
    const result = response;
    if (process.env.REACT_APP_PROJECT_MODE === "development") {
      return result.data;
    } else {
      return await useEncryption.decryptData(result.data);
    }
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
