const appConfig = {
  apiPrefix: "/api",
  authenticatedEntryPath: "/app/doctor/dashboard",
  unAuthenticatedEntryPath: "/doctor/sign-in",
  tourPath: "/doctor/sign-in",
  enableMock: true,
  pagePerData: 10,
  configurationPagePerData: 8,
};

export default appConfig;
